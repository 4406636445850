import districts from '../../districts.json';

const districtOptions = [{
  label: '台灣',
  value: '台灣',
  children: Object.keys(districts).map((key) => ({
    label: key,
    value: key,
    children: districts[key].map((child) => ({ label: child, value: child })),
  })),
}, {
  label: '外國',
  value: '外國',
}];

const defaultFields = {
  info: [{
    name: 'serial',
    label: '床組編號',
  }, {
    name: 'gateway',
    label: 'Gateway ID',
  }, {
    name: 'district',
    label: '行政區',
    options: districtOptions,
  }, {
    name: 'address',
    label: '地址',
  }, {
    name: 'timezone',
    label: '時區',
    options: Array.from(Array(25).keys())
      .map((value) => value - 12)
      .map((value) => ({ value, label: `GMT ${value >= 0 ? '+' : ''}${value}` })),
  }, {
    name: 'size',
    label: '尺寸',
    options: [3, 3.5, 5, 6, 7].map((num) => ({ value: num, label: `${num} 尺` })),
  }],
  config: [{
    name: 'monitorCycle',
    label: '監測排程週期',
    options: Array.from(Array(3).keys())
      .map((value) => (value + 1) * 10)
      .map((value) => ({ value, label: `${value} 天` })),
  }, {
    name: 'ventilationCycle',
    label: '自動排氣週期',
    options: Array.from(Array(6).keys())
      .map((value) => ({ value, label: value === 0 ? '關閉' : `${value} 天` })),
  }, {
    name: 'ventilationPeriod',
    label: '自動排氣時長',
    options: [1, 2, 3].map((value) => ({ value, label: `${value} 小時` })),
  }, {
    name: 'scoreThreshold',
    label: '固定模組後標準分數',
    isNumber: true,
  }, {
    name: 'pingInterval',
    label: '每日 Gateway 連線檢測',
    options: [0, 12, 4, 2, 1]
      .map((value) => ({ value, label: value === 0 ? '關閉' : `${24 / value} 次` })),
  }, {
    name: 'monthlyReport',
    label: '每月自動發送 Email 報告',
    options: [
      { value: true, label: '開啟' },
      { value: false, label: '關閉' },
    ],
  }, {
    name: 'controlExpire',
    label: '調整訂閱到期日',
    isDate: true,
    optional: true,
  }],
  user: [{
    name: 'name',
    label: '姓名',
  }, {
    name: 'account',
    label: '匯嘉帳號',
  }, {
    name: 'password',
    label: '匯嘉密碼',
  }, {
    name: 'dataExpire',
    label: '資料訂閱到期日',
    isDate: true,
    optional: true,
  }, {
    name: 'gender',
    label: '性別',
    options: [
      { value: 'male', label: '男性' },
      { value: 'female', label: '女性' },
    ],
  }, {
    name: 'height',
    label: '身高',
    isNumber: true,
  }, {
    name: 'weight',
    label: '體重',
    isNumber: true,
  }, {
    name: 'bust',
    label: '胸圍',
    options: [
      { value: 'S' },
      { value: 'M' },
      { value: 'L' },
      { value: 'XL' },
    ],
  }, {
    name: 'hips',
    label: '臀圍',
    options: [
      { value: 'S' },
      { value: 'M' },
      { value: 'L' },
      { value: 'XL' },
    ],
  }, {
    name: 'module',
    label: '模組',
    options: Array.from(Array(5).keys())
      .map((value) => value + 1)
      .map((value) => ({ value, label: value })),
  }, {
    name: 'phone',
    label: '電話',
    optional: true,
  }, {
    name: 'line',
    label: 'Line',
    optional: true,
  }, {
    name: 'wechat',
    label: 'WeChat',
    optional: true,
  }, {
    name: 'mail',
    label: 'E-mail',
    optional: true,
  }, {
    name: 'comment',
    label: '備註',
    optional: true,
  }],
};

const defaultValues = {
  monitorCycle: 30,
  monthlyReport: true,
  pingInterval: 1,
  scoreThreshold: 75,
  timezone: 8,
  ventilationCycle: 3,
  ventilationPeriod: 1,
};

const getFields = (type, device) => {
  const info = (type === 'observant')
    ? defaultFields.info.filter((field) => field.name !== 'gateway')
    : defaultFields.info;

  const config = (type === 'observant')
    ? defaultFields.config.filter((field) => field.name === 'monthlyReport')
    : defaultFields.config;

  const user = (type === 'observant') || (device !== undefined)
    ? defaultFields.user.filter((field) => field.name !== 'module')
    : defaultFields.user;

  return { info, config, user };
};

export { getFields, defaultValues };
