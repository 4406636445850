import React from 'react';
import {
  Steps, Button, Divider, Space,
} from 'antd';
import APIContext from '../../../contexts/APIContext';
import InfoPanel from '../InfoPanel';
import Configurer from './Configurer';

const styles = {
  container: {
    padding: 20,
    paddingTop: 60,
  },
  infoPanel: {
    padding: 20,
  },
  stepButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
};

const steps = ['確認床組', '測試與設定', '製造完成'];

const fields = [
  { key: 'serial', label: '床組編號' },
  { key: 'gateway', label: 'Gateway' },
  { key: 'type', label: '床組類型', valueMap: { intelligent: '智慧床', observant: '數據床' } },
  { key: 'ventilationPeriod', label: '排氣時長' },
  { key: 'modules', label: '模組', merge: 'module' },
];

const condition = { status: 'manufacture' };

const Factory = () => {
  const [step, setStep] = React.useState(0);
  const [info, setInfo] = React.useState(null);
  const { callAPI, APIs } = React.useContext(APIContext);

  const next = React.useCallback(() => {
    const offset = info && info.type === 'intelligent' ? 1 : 2;
    setStep((current) => current + offset);
  }, [setStep, info]);

  const prev = React.useCallback(() => {
    const offset = info && info.type === 'intelligent' ? 1 : 2;
    setStep((current) => current - offset);
  }, [setStep, info]);

  const finish = React.useCallback(() => {
    callAPI(APIs.deviceBuilt, { serial: info.serial })
      .then(() => {
        setStep(0);
        setInfo(null);
      });
  }, [info, callAPI, APIs, setStep, setInfo]);

  return (
    <div style={styles.container}>
      <Steps current={step} progressDot>
        {steps.map((item) => <Steps.Step key={item} title={item} />)}
      </Steps>
      <Divider />
      <div style={styles.content}>
        {step === 0 && (
          <Space direction="vertical">
            <InfoPanel info={info} onFetched={setInfo} condition={condition} fields={fields} />
            {info && <Button type="primary" onClick={next}>床組資訊已確認</Button>}
          </Space>
        )}
        {step === 1 && (
          <Configurer onFinished={next} info={info} />
        )}
        {step === 2 && <Button type="primary" onClick={finish}>完成測試準備出貨</Button>}
      </div>
      <div style={styles.stepButton}>
        {step > 0 && <Button onClick={prev}>上一步</Button>}
      </div>
    </div>
  );
};

export default Factory;
