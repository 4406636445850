import React from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import Filter from './Filter';
import Summary from './Summary';
import List from './List';
import APIContext from '../../../../contexts/APIContext';
import determineStatus from '../utils';

const styles = {
  container: {
    padding: 60,
  },
  right: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  tableBox: {
    marginTop: 20,
    marginBottom: 80,
  },
};

const filterBySearch = (items, search) => {
  if (search === '') {
    return items;
  }

  return items.filter((item) => (
    item.serial.includes(search)
    || item.left.name.includes(search)
    || (item.right && item.right.name.includes(search))
  ));
};

const filterByType = (items, type) => {
  if (type === 'all') {
    return items;
  }
  return items.filter((item) => item.type === type);
};

const filterByExpire = (items, expire) => {
  if (expire === 'all') {
    return items;
  }

  const now = moment();
  return items.filter((item) => {
    const timestamps = [item.left[expire], item.right && item.right[expire]];
    const found = timestamps.find((timestamp) => {
      if (!timestamp) {
        return false;
      }
      const diff = moment(timestamp).diff(now, 'day');
      return diff <= 10;
    });
    return found !== undefined;
  });
};

const Dashboard = () => {
  const [items, setItems] = React.useState([]);
  const [filter, setFilter] = React.useState({ search: '', type: 'all', expire: 'all' });
  const [sumData, setSumData] = React.useState(null);
  const { callAPI, APIs } = React.useContext(APIContext);
  const history = useHistory();

  React.useEffect(() => {
    Promise.all([
      callAPI(APIs.deviceList),
      callAPI(APIs.dataSummary),
    ])
      .then(([devices, summary]) => {
        const transformed = devices.map((device) => {
          const { status } = device;
          const { sleepers, ...rest } = device;
          const leftRight = device.sleepers.reduce((sum, sleeper) => {
            const { persist, ...sRest } = sleeper;
            return {
              ...sum,
              [sleeper.side]: { ...sRest, status: determineStatus(sleeper, status), ...persist },
            };
          }, {});

          return { ...rest, ...leftRight };
        });

        setItems(transformed);
        setSumData(summary);
      });
  }, [setItems, setSumData, callAPI, APIs]);

  const filteredItems = React.useMemo(() => {
    const bySearch = filterBySearch(items, filter.search);
    const byType = filterByType(bySearch, filter.type);
    const byExpire = filterByExpire(byType, filter.expire);
    return byExpire;
  }, [items, filter]);

  const createDevice = React.useCallback(() => { history.push('/customers/edit'); }, [history]);

  return (
    <div style={styles.container}>
      <Filter filter={filter} onFilterChanged={setFilter} />
      <div style={styles.tableBox}>
        <Summary sumData={sumData} items={filteredItems} />
      </div>
      <div style={styles.right}>
        <Button type="primary" onClick={createDevice}>新增</Button>
      </div>
      <div style={styles.tableBox}>
        <List items={filteredItems} />
      </div>
    </div>
  );
};

export default Dashboard;
