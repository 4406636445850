import React, { useState, useCallback, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { request, APIs } from '../api';
import Login from './Login';
import Main from './Main';

const App = () => {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  const clearUser = useCallback(() => setUser(null), [setUser]);

  const fetchUser = useCallback(() => {
    setLoading(true);
    request(APIs.getUser)
      .then(setUser)
      .catch(clearUser)
      .then(() => setLoading(false));
  }, [setUser, clearUser]);

  useEffect(fetchUser, [fetchUser]);

  if (loading) {
    return null;
  }

  return (
    <Router>
      <Switch>
        <Route path="/login">
          {user ? <Redirect to="/" /> : <Login onLoggedIn={fetchUser} />}
        </Route>
        <Route path="/">
          {!user ? <Redirect to="/login" /> : <Main user={user} onLogout={clearUser} />}
        </Route>
      </Switch>
    </Router>
  );
};

export default App;

/*

const renderRoute = (user, clearUser) => ({ location }) => {
  if (!user) {
    return <Redirect to="/login" />;
  }

  const { pathname } = location;
  console.log(pathname);

  if (user.role === 'driver' && pathname !== '/activate') {
    return <Redirect to="/activate" />;
  }

  if (user.role === 'manufacturer' && pathname !== '/factory') {
    return <Redirect to="/factory" />;
  }

  if ((user.role === 'admin' || user.role === 'staff')
  && (pathname === '/activate' || pathname === '/factory')) {
    return <Redirect to="/" />;
  }

  let page;
  switch (pathname) {
    case '/activate':
      page = <Activate />;
      break;
    case '/factory':
      page = <Factory />;
      break;
    default:
      page = <Main user={user} onLogout={clearUser} />;
  }
  return page;
};

*/
