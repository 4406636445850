import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Input, Button, Form } from 'antd';
import { request, APIs } from '../api';

const styles = {
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  panel: {
    width: 400,
  },
  error: {
    color: 'red',
  },
};

const errorMap = {
  406: '客服人員帳號密碼錯誤，請重新輸入',
};

const Login = ({ onLoggedIn }) => {
  const [error, setError] = useState('');
  const [form] = Form.useForm();

  const login = useCallback((values) => {
    request(APIs.login, values)
      .then(onLoggedIn)
      .catch((err) => {
        const message = errorMap[err.status];
        if (message) {
          setError(message);
        } else {
          setError(`${err.status}: ${err.message}`);
        }
        form.resetFields();
      });
  }, [onLoggedIn, setError, form]);

  const clearError = useCallback(() => {
    setError('');
  }, [setError]);

  return (
    <div style={styles.container}>
      <div style={styles.panel}>
        <Form onFinish={login} form={form} onValuesChange={clearError}>
          <Form.Item
            name="account"
            rules={[{ required: true, message: '請輸入客服人員帳號' }]}
          >
            <Input placeholder="客服人員帳號" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: '請輸入客服人員密碼' }]}
          >
            <Input.Password placeholder="客服人員密碼" />
          </Form.Item>
          <Form.Item>
            <div style={styles.error}>{error}</div>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">登入</Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

Login.propTypes = {
  onLoggedIn: PropTypes.func.isRequired,
};

export default Login;
