import React from 'react';
import download from 'downloadjs';
import moment from 'moment';
import {
  Table, Form, Input, Button, Select, Cascader, DatePicker,
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import APIContext from '../../contexts/APIContext';
import districts from './districts.json';

const styles = {
  container: {
    padding: 60,
  },
  formBlock: {
    marginBottom: 20,
  },
  eventSelect: {
    width: 100,
  },
};

const eventOptions = [
  { value: 'cough', label: '咳嗽' },
  { value: 'fever', label: '發燒' },
];

const districtOptions = [{
  label: '台灣',
  value: '台灣',
  children: Object.keys(districts).map((key) => ({
    label: key,
    value: key,
    children: districts[key].map((child) => ({ label: child, value: child })),
  })),
}, {
  label: '外國',
  value: '外國',
}];

const transformFilter = (filter) => {
  const condition = {};
  Object.keys(filter).forEach((key) => {
    const value = filter[key];
    if (value === undefined) {
      return;
    }

    if (key === 'time') {
      condition.since = value[0].valueOf();
      condition.until = value[1].valueOf();
      return;
    }

    condition[key] = value;
  });

  return condition;
};

const Alerts = () => {
  const { state } = useLocation();
  const [alerts, setAlerts] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState({ current: 1, pageSize: 10 });
  const [filter, setFilter] = React.useState({ serial: state });
  const [form] = Form.useForm();
  const { callAPI, APIs } = React.useContext(APIContext);
  const history = useHistory();

  const exportAlerts = React.useCallback(() => {
    const body = { ...transformFilter(filter) };

    callAPI(APIs.alertExport, body)
      .then((data) => {
        download(data, 'alerts.csv', 'text/plain');
      });
  }, [filter, callAPI, APIs]);

  const fetchAlerts = React.useCallback((condition, offset) => {
    setLoading(true);
    const body = { ...transformFilter(condition), offset };

    callAPI(APIs.alert, body)
      .then((newAlerts) => {
        setAlerts((original) => {
          if (offset >= original.length) {
            const blank = Array(offset - original.length);
            return original.concat(blank).concat(newAlerts);
          }

          const before = original.slice(0, offset);
          const after = original.slice(offset + newAlerts.length);
          return before.concat(newAlerts).concat(after);
        });
        setLoading(false);
      });
  }, [callAPI, APIs]);

  const fetchAlertCount = React.useCallback((condition) => {
    const body = transformFilter(condition);
    callAPI(APIs.alertCount, body)
      .then((total) => {
        setPage((original) => ({ ...original, total }));
      });
  }, [callAPI, APIs]);

  const onPageChange = React.useCallback((pagination) => {
    const { current, pageSize } = pagination;
    const offset = (current - 1) * pageSize;
    if (offset >= alerts.length || !alerts[offset]) {
      fetchAlerts(filter, offset);
    }
    setPage(pagination);
  }, [alerts, filter, fetchAlerts]);

  const onSearch = React.useCallback((condition) => {
    setFilter(condition);
    setAlerts([]);
    setPage((origin) => ({ ...origin, current: 1 }));
    form.resetFields();
  }, [form]);

  React.useEffect(() => {
    Promise.all([
      fetchAlertCount(filter),
      fetchAlerts(filter, 0),
    ]);
  }, [filter, fetchAlertCount, fetchAlerts]);

  const columns = React.useMemo(() => [{
    title: '時間',
    dataIndex: 'time',
    sorter: true,
    render: (time) => moment(time).format('YYYY/MM/DD HH:mm:ss'),
  }, {
    title: '警報類型',
    dataIndex: 'event',
    render: (event) => (eventOptions.find((option) => option.value === event) || {}).label,
  }, {
    title: '床組',
    dataIndex: 'serial',
    render: (value) => (
      <Button type="link" onClick={() => history.push('/customers/detail', value)}>
        {value}
      </Button>
    ),
  }, {
    title: '姓名',
    dataIndex: 'name',
  }, {
    title: '行政區',
    dataIndex: 'district',
  }], [history]);

  return (
    <div style={styles.container}>
      <div style={styles.formBlock}>
        <Form onFinish={onSearch} form={form} layout="inline">
          <Form.Item name="name">
            <Input placeholder="姓名" />
          </Form.Item>
          <Form.Item name="event">
            <Select options={eventOptions} style={styles.eventSelect} />
          </Form.Item>
          <Form.Item name="time">
            <DatePicker.RangePicker showTime={{ format: 'HH:mm' }} />
          </Form.Item>
          <Form.Item name="district">
            <Cascader options={districtOptions} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">搜尋</Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={exportAlerts}>警報匯出</Button>
          </Form.Item>
        </Form>
      </div>
      <Table
        columns={columns}
        rowKey="_id"
        pagination={page}
        dataSource={alerts}
        loading={loading}
        onChange={onPageChange}
      />
    </div>
  );
};

export default Alerts;
