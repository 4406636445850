import React from 'react';
import {
  Button, Space, Col, Row, Divider,
} from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import InfoDescription from './InfoDescription';
import Sleeper from './Sleeper';
import APIContext from '../../../../contexts/APIContext';
import determineStatus from '../utils';

const styles = {
  container: {
    padding: 60,
  },
  commandBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    marginBottom: 20,
  },
};

const majorFields = [
  { key: 'type', label: '類型', valueMap: { intelligent: '智慧床', observant: '數據床' } },
  { key: 'district', label: '行政區' },
  { key: 'address', label: '地址' },
];

const minorFields = [
  { key: 'gateway', label: 'Gateway ID' },
  { key: 'activated', label: '啟用日期', isDate: true },
  { key: 'timezone', label: '時區', render: (v) => `UTC ${v >= 0 ? '+' : '-'}${v}` },
  { key: 'size', label: '尺寸', render: (v) => `${v} 尺` },
  { key: 'monitorCycle', label: '監測排程週期', render: (v) => `${v} 天` },
  { key: 'ventilationCycle', label: '自動排氣週期', render: (v) => `每 ${v} 天 1 次` },
  { key: 'ventilationPeriod', label: '自動排氣時長', render: (v) => `${v} 小時` },
  { key: 'scoreThreshold', label: '固定模組後標準分數', render: (v) => `${v} 分` },
  { key: 'pingInterval', label: '每日 Gateway 連線檢測', render: (v) => `每 ${v} 小時 1 次` },
  { key: 'monthlyReport', label: '每月自動發送 Email 報告', isBoolean: true },
  { key: 'controlExpire', label: '調整訂閱到期日', isDate: true },
];

const Detail = () => {
  const { state } = useLocation();
  const history = useHistory();
  const [device, setDevice] = React.useState(null);
  const { callAPI, APIs } = React.useContext(APIContext);

  const controlEnabled = React.useMemo(() => {
    if (!device) {
      return false;
    }
    return device.activated && device.type === 'intelligent';
  }, [device]);

  const querySleeper = React.useCallback(() => {
    callAPI(APIs.deviceInfo, { serial: state })
      .then((result) => {
        if (!result) {
          return;
        }

        const { sleepers, ...rest } = result;
        const leftRight = result.sleepers.reduce((sum, sleeper) => {
          const { persist, ...sRest } = sleeper;
          return {
            ...sum,
            [sleeper.side]: { ...sRest, status: determineStatus(sleeper, rest.status), ...persist },
          };
        }, {});

        const updated = { ...rest, ...leftRight };
        setDevice(updated);
      });
  }, [state, callAPI, APIs]);

  const refill = React.useCallback(() => {
    callAPI(APIs.refill, { serial: device.serial });
  }, [callAPI, APIs, device]);

  const ventilate = React.useCallback(() => {
    callAPI(APIs.ventilate, { serial: device.serial, period: device.ventilationPeriod });
  }, [callAPI, APIs, device]);

  React.useEffect(() => {
    if (!device) {
      querySleeper();
    }
  }, [device, querySleeper]);

  if (!device) {
    return null;
  }

  const { left, right, serial } = device;
  return (
    <div style={styles.container}>
      <InfoDescription
        title={serial}
        data={device}
        major={majorFields}
        minor={minorFields}
        column={3}
      />

      <div style={styles.commandBlock}>
        <Space>
          <Button onClick={() => history.push('/logs', serial)}>工作記錄</Button>
          <Button onClick={() => history.push('/errors', serial)}>錯誤訊息</Button>
          <Button onClick={() => history.push('/alerts', serial)}>警報紀錄</Button>
        </Space>
        <Space>
          <Button onClick={() => history.push('/customers/edit', device)}>床組設定</Button>
          <Button onClick={refill}>手動充氣</Button>
          <Button onClick={ventilate}>手動通風</Button>
        </Space>
      </div>
      <Divider />
      <Row gutter={40}>
        {right && (
          <Col span={12}>
            <Sleeper
              controlEnabled={controlEnabled}
              serial={serial}
              side="right"
              data={right}
              scoreThreshold={device.scoreThreshold}
              onControlSuccess={querySleeper}
            />
          </Col>
        )}
        <Col span={right ? 12 : 24}>
          <Sleeper
            controlEnabled={controlEnabled}
            serial={serial}
            side="left"
            data={left}
            scoreThreshold={device.scoreThreshold}
            onControlSuccess={querySleeper}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Detail;
