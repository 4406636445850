import React from 'react';
import PropTypes from 'prop-types';
import {
  Space, Typography, Input, Button,
} from 'antd';
import APIContext from '../../contexts/APIContext';

const styles = {
  container: {
    padding: 20,
  },
};

const mergeSleeperFields = (data, key) => {
  const { sleepers } = data;
  return sleepers.map((sleeper) => sleeper[key]);
};

const InfoPanel = ({
  info, fields, condition, onFetched,
}) => {
  const [serial, setSerial] = React.useState('');
  const { callAPI, APIs } = React.useContext(APIContext);

  const updateSerial = React.useCallback((e) => setSerial(e.target.value), [setSerial]);

  const queryDevice = React.useCallback(() => {
    if (!serial.length) {
      return;
    }
    callAPI(APIs.deviceInfo, { serial, ...condition })
      .then((device) => {
        if (!device) {
          onFetched(null);
          return;
        }

        const toExtract = fields.filter((field) => !field.merge);
        const extracted = toExtract.reduce((sum, cur) => ({
          ...sum,
          [cur.key]: device[cur.key],
        }), {});

        const toMerge = fields.filter((field) => field.merge);
        const merged = toMerge.reduce((sum, cur) => ({
          ...sum,
          [cur.key]: mergeSleeperFields(device, cur.merge),
        }), {});

        onFetched({
          serial: device.serial,
          ...merged,
          ...extracted,
        });
      });
  }, [serial, fields, onFetched, condition, callAPI, APIs]);

  return (
    <Space direction="vertical">
      <Space>
        <Input allowClear value={serial} onChange={updateSerial} />
        <Button onClick={queryDevice}>取得床組資訊</Button>
      </Space>
      <div style={styles.container}>
        <Space direction="vertical">
          {info && fields.map((field) => (
            <Typography.Text key={field.key}>
              {`${field.label}: ${field.valueMap ? field.valueMap[info[field.key]] : info[field.key]}`}
            </Typography.Text>
          ))}
        </Space>
      </div>
    </Space>

  );
};

InfoPanel.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    merge: PropTypes.string,
  })).isRequired,
  onFetched: PropTypes.func.isRequired,
  info: PropTypes.shape({}),
  condition: PropTypes.shape({}),
};

InfoPanel.defaultProps = {
  info: null,
  condition: {},
};

export default InfoPanel;
