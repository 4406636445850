import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Descriptions, Popover, Button } from 'antd';

const InfoDescription = ({
  data, major, minor, column, title,
}) => {
  const extra = React.useMemo(() => {
    if (!minor) {
      return null;
    }
    const content = <InfoDescription data={data} major={minor} />;
    return (
      <Popover trigger="click" content={content} placement="topRight">
        <Button type="link" icon={<InfoCircleOutlined />} />
      </Popover>
    );
  }, [data, minor]);

  if (!data) {
    return null;
  }

  return (
    <Descriptions title={title} bordered size="small" extra={extra} column={column}>
      {major.map((field) => {
        let content = data[field.key];
        if (field.render) {
          content = field.render(data[field.key]);
        } else if (field.valueMap) {
          content = field.valueMap[data[field.key]];
        } else if (field.isDate) {
          content = moment(data[field.key]).format('YYYY/MM/DD');
        } else if (field.isBoolean) {
          content = content ? '是' : '否';
        }

        return (
          <Descriptions.Item label={field.label} key={field.key}>
            {content}
          </Descriptions.Item>
        );
      })}
    </Descriptions>
  );
};

InfoDescription.propTypes = {
  title: PropTypes.string,
  data: PropTypes.shape({}).isRequired,
  major: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  minor: PropTypes.arrayOf(PropTypes.shape({})),
  column: PropTypes.number,
};

InfoDescription.defaultProps = {
  minor: null,
  column: 2,
  title: '',
};

export default InfoDescription;
