import qs from 'querystring';
import createError from 'http-errors';

const prefix = '/api';

const parseContent = (res) => {
  const type = res.headers.get('content-type');
  if (type) {
    if (type.includes('application/json')) {
      return res.json();
    }

    if (type.includes('text')) {
      return res.text();
    }
  }

  return Promise.resolve();
};

const rejectNotSuccess = (res) => {
  if (!res.ok) {
    return res.text().then((text) => Promise.reject(createError(res.status, text)));
  }
  return Promise.resolve(res);
};

const request = ([method, endpoint], params) => {
  const url = method === 'GET' && params
    ? `${prefix}/${endpoint}?${qs.stringify(params)}`
    : `${prefix}/${endpoint}`;
  const body = method === 'GET' ? null : JSON.stringify(params);
  const headers = { 'content-type': 'application/json' };
  const credentials = 'include';
  const options = {
    method, body, headers, credentials,
  };

  return fetch(url, options)
    .then(rejectNotSuccess)
    .then(parseContent);
};

const APIs = {
  getUser: ['GET', 'user/account'],
  createUser: ['POST', 'user/account'],
  updateUser: ['PUT', 'user/account'],
  deleteUser: ['DELETE', 'user/account'],
  login: ['POST', 'user/login'],
  logout: ['DELETE', 'user/logout'],
  userList: ['GET', 'user/list'],
  createDevice: ['POST', 'device/create'],
  updateDevice: ['PUT', 'device/update'],
  deleteDevice: ['DELETE', 'device/delete'],
  activateDevice: ['PUT', 'device/activate'],
  deviceList: ['GET', 'device/list'],
  deviceInfo: ['GET', 'device/info'],
  preset: ['POST', 'device/preset'],
  ventilate: ['POST', 'device/ventilation'],
  refill: ['POST', 'device/refill'],
  reschedule: ['POST', 'device/reschedule'],
  custom: ['POST', 'device/custom'],
  deviceBuilt: ['PUT', 'device/built'],
  dataSummary: ['GET', 'data/summary'],
  sleepData: ['GET', 'data/sleep'],
  log: ['GET', 'data/log'],
  logCount: ['GET', 'data/log-count'],
  error: ['GET', 'data/error'],
  errorCount: ['GET', 'data/error-count'],
  alert: ['GET', 'data/alert'],
  alertCount: ['GET', 'data/alert-count'],
  alertExport: ['GET', 'data/alert-export'],
  bigdata: ['GET', 'data/bigdata'],
  notification: ['GET', 'data/notification'],
  dismiss: ['PUT', 'data/dismiss'],
  updateSystem: ['PUT', 'data/system'],
  getSystem: ['GET', 'data/system'],
  updateMonitorConfig: ['PUT', 'monitor/config'],
  getMonitorConfig: ['GET', 'monitor/config'],
};

export { request, APIs };
