import React from 'react';
import PropTypes from 'prop-types';
import {
  Space, Select, Form, Divider, Button,
} from 'antd';
import APIContext from '../../../../contexts/APIContext';

const moduleOptions = [1, 2, 3, 4, 5];
const sectionOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const detailOptionRules = [
  [],
  [0, 1, 2],
  [-2, -1, 0, 1, 2],
  [-2, -1, 0, 1, 2],
  [-2, -1, 0, 1, 2],
  [-2, -1, 0],
];

const details = [
  { key: 'back', label: '背' },
  { key: 'waist', label: '腰' },
  { key: 'bottom', label: '臀' },
];

const initialValues = {
  module: 1,
  section: 1,
  back: 0,
  waist: 0,
  bottom: 0,
};

const labelCol = { span: 8 };
const wrapperCol = { span: 12 };
const buttonWrapperCol = { offset: 8, span: 12 };

const styles = {
  container: {
    width: 300,
    height: 300,
  },
};

const ModuleConfig = ({ serial, side, onSuccess }) => {
  const [action, setAction] = React.useState('reschedule');
  const [detail, setDetail] = React.useState(false);
  const [detailOptions, setDetailOptions] = React.useState(detailOptionRules[1]);
  const [form] = Form.useForm();
  const { callAPI, APIs } = React.useContext(APIContext);

  const updateDetails = React.useCallback((update) => {
    if (!update.module) {
      return;
    }
    setDetailOptions(detailOptionRules[update.module]);
    form.setFieldsValue({ back: 0, waist: 0, bottom: 0 });
  }, [form]);

  const submit = React.useCallback((values) => {
    const api = (action === 'reschedule') ? APIs.reschedule : APIs.custom;
    const { module, section, ...rest } = values;
    let body = { serial, side, module };

    if (action === 'custom') {
      if (detail) {
        body = { ...body, ...rest, section: 1 };
      } else {
        body = {
          ...body,
          section,
          back: 0,
          waist: 0,
          bottom: 0,
        };
      }
    }
    callAPI(api, body).then(onSuccess);
  }, [callAPI, APIs, detail, action, serial, side, onSuccess]);

  return (
    <div style={styles.container}>
      <Space>
        <Select value={action} onChange={setAction}>
          <Select.Option value="reschedule">重新排程</Select.Option>
          <Select.Option value="custom">手動調整</Select.Option>
        </Select>
        {action === 'custom' && (
          <Select value={detail} onChange={setDetail}>
            <Select.Option value={false}>區段調整</Select.Option>
            <Select.Option value>細節調整</Select.Option>
          </Select>
        )}
      </Space>
      <Divider />
      <Form
        form={form}
        initialValues={initialValues}
        onValuesChange={updateDetails}
        onFinish={submit}
        labelCol={labelCol}
        wrapperCol={wrapperCol}
      >
        <Form.Item name="module" label="模組">
          <Select>
            {moduleOptions.map((option) => (
              <Select.Option key={option} value={option}>{option}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        {action === 'custom' && !detail && (
          <Form.Item name="section" hidden={detail} label="區段">
            <Select>
              {sectionOptions.map((option) => (
                <Select.Option key={option} value={option}>{option}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {action === 'custom' && detail && (
          <>
            {details.map(({ key, label }) => (
              <Form.Item name={key} key={key} label={label}>
                <Select>
                  {detailOptions.map((option) => (
                    <Select.Option key={option} value={option}>{option}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ))}
          </>
        )}
        <Form.Item wrapperCol={buttonWrapperCol}>
          <Button type="primary" htmlType="submit">送出</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

ModuleConfig.propTypes = {
  serial: PropTypes.string.isRequired,
  side: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default ModuleConfig;
