import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, Route, Redirect } from 'react-router-dom';

const rules = {
  admin: { defaultPath: '/customers', notAllowed: ['/activate', '/factory'] },
  staff: { defaultPath: '/customers', notAllowed: ['/activate', '/factory', '/accounts'] },
  driver: { defaultPath: '/activate', allowed: ['/activate'] },
  manufacturer: { defaultPath: '/factory', allowed: ['/factory'] },
};

const RestrictedRoute = ({ children, role, ...rest }) => {
  const { pathname } = useLocation();
  const { allowed, notAllowed, defaultPath } = rules[role];

  if ((allowed && !allowed.includes(pathname)) || (notAllowed && notAllowed.includes(pathname))) {
    return <Redirect to={defaultPath} />;
  }

  // eslint-disable-next-line
  return <Route {...rest}>{children}</Route>;
};

RestrictedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  role: PropTypes.string.isRequired,
};

const RedirectDefault = ({ role }) => <Redirect to={rules[role].defaultPath} />;
RedirectDefault.propTypes = { role: PropTypes.string.isRequired };

export { RestrictedRoute, RedirectDefault };
