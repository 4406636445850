import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  Table, Button, Input, Space, Checkbox,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import APIContext from '../../../contexts/APIContext';

const styles = {
  container: {
    padding: 60,
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
};

const roleOptions = [
  { value: 'admin', label: '系統管理員' },
  { value: 'staff', label: '客服人員' },
  { value: 'driver', label: '司機' },
  { value: 'manufacturer', label: '工廠製造員' },
  { value: 'client', label: '資料串接帳號' },
];

const filterByName = (users, name) => {
  if (name === '') {
    return users;
  }
  return users.filter((user) => user.name.includes(name));
};

const filterByRole = (users, roles) => (
  users.filter((user) => roles.includes(user.role))
);

const AccountList = () => {
  const history = useHistory();
  const [search, setSearch] = React.useState('');
  const [users, setUsers] = React.useState([]);
  const [roles, setRoles] = React.useState(roleOptions.map((role) => role.value));
  const { callAPI, APIs } = React.useContext(APIContext);

  React.useEffect(() => {
    callAPI(APIs.userList)
      .then(setUsers);
  }, [callAPI, APIs, setUsers]);

  const columns = React.useMemo(() => ([
    { title: '帳號', dataIndex: 'account' },
    { title: '名稱', dataIndex: 'name', width: 300 },
    {
      title: '身份',
      dataIndex: 'role',
      width: 300,
      render: (value) => {
        const target = roleOptions.find((option) => option.value === value);
        return target.label;
      },
    },
    {
      title: '建立時間',
      width: 300,
      dataIndex: 'created',
      render: (value) => moment(value).format('YYYY/MM/DD'),
    },
    {
      title: '',
      width: 100,
      render: (value) => (
        <Button icon={<EditOutlined />} onClick={() => history.push('/accounts/edit', value)} />
      ),
    },
  ]), [history]);

  const filtered = React.useMemo(() => (
    filterByRole(filterByName(users, search), roles)
  ), [users, search, roles]);

  const createAccount = React.useCallback(() => {
    history.push('/accounts/edit');
  }, [history]);

  const updateSearch = React.useCallback((e) => {
    setSearch(e.target.value);
  }, [setSearch]);

  return (
    <div style={styles.container}>
      <div style={styles.controls}>
        <Space>
          <Checkbox.Group
            options={roleOptions}
            value={roles}
            onChange={setRoles}
          />
          <Input placeholder="依名稱搜尋" onChange={updateSearch} />
        </Space>
        <Button type="primary" onClick={createAccount}>新增</Button>
      </div>

      <Table dataSource={filtered} columns={columns} rowKey="_id" />
    </div>
  );
};

export default AccountList;
