import React from 'react';
import {
  Collapse, Row, Col, Spin,
} from 'antd';
import APIContext from '../../contexts/APIContext';

const styles = {
  container: {
    padding: 60,
  },
  separated: {
    borderWidth: 0,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderStyle: 'solid',
    borderColor: 'lightGray',
  },
};

const translations = {
  male: '男',
  female: '女',
  short: '矮',
  tall: '高',
  light: '輕',
  heavy: '重',
  medium: '中',
};

const modules = [1, 2, 3, 4, 5];
const genders = ['male', 'female'];
const heights = ['short', 'medium', 'tall'];
const weights = ['light', 'medium', 'heavy'];

const orderedSections = (data, condition) => {
  const filtered = data.filter((d) => (
    d.module === condition.module && d.gender === condition.gender
    && d.height === condition.height && d.weight === condition.weight
  ));

  filtered.sort((a, b) => {
    if (a.average !== b.average) {
      return b.average - a.average;
    }
    return a.section[0] - b.section[0];
  });

  return filtered.map((d) => d.section.join(', '));
};

const BigData = () => {
  const [data, setData] = React.useState([]);
  const { callAPI, APIs } = React.useContext(APIContext);

  React.useEffect(() => {
    callAPI(APIs.bigdata).then(setData);
  }, [callAPI, APIs, setData]);

  if (!data.length) {
    return (
      <div style={styles.container}>
        <Spin />
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <Collapse defaultActiveKey={modules}>
        {modules.map((module) => (
          <Collapse.Panel header={`模組${module}`} key={module}>
            {genders.map((gender) => (
              <Row justify="center" align="middle" gutter={[0, 20]} key={gender}>
                <Col span={2}>{translations[gender]}</Col>
                <Col span={22}>
                  <Row>
                    {heights.map((height, i) => (
                      <Col span={8} key={height} style={i === 1 ? styles.separated : {}}>
                        <Row justify="center" align="middle">{translations[height]}</Row>
                        <Row>
                          {weights.map((weight) => (
                            <Col span={8} key={weight}>
                              <Row justify="center" align="middle">{translations[weight]}</Row>
                              {orderedSections(data, {
                                module,
                                gender,
                                height,
                                weight,
                              }).map((section) => (
                                <Row justify="center" align="middle" key={section}>{section}</Row>
                              ))}
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>

            ))}
          </Collapse.Panel>

        ))}
      </Collapse>
    </div>
  );
};

export default BigData;
