import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Popover, Button, Select } from 'antd';
import ModuleConfig from './ModuleConfig';
import InfoDescription from './InfoDescription';
import Chart from './Chart';

const majorFields = [
  { key: 'gender', label: '性別', valueMap: { male: '男', female: '女' } },
  { key: 'height', label: '身高' },
  { key: 'weight', label: '體重' },
  {
    key: 'status',
    label: '狀態',
    valueMap: { monitor: '數據監測中', schedule: '模組排程中', custom: '手動固定模組' },
    needControl: true,
  },
  {
    key: 'schedule',
    label: '排程',
    needControl: true,
    render: (value) => (value < 0 ? '已結束' : value),
  },
  {
    key: 'current',
    label: '現在模組',
    needControl: true,
    render: (value) => {
      if (!value) {
        return '';
      }

      const {
        module, section, back, waist, bottom,
      } = value;
      const array = section.map((sec, i) => {
        const str = `${module}-${sec}_${back}/${waist}/${bottom}`;
        if (i === 0) {
          return str;
        }
        return `(${str})`;
      });
      return array.join(' ');
    },
  },
];

const minorFields = [
  { key: 'account', label: '匯嘉帳號' },
  { key: 'password', label: '匯嘉密碼' },
  { key: 'dataExpire', label: '資料訂閱到期日', isDate: true },
  { key: 'bust', label: '胸圍' },
  { key: 'hips', label: '臀圍' },
  { key: 'phone', label: '電話' },
  { key: 'line', label: 'Line' },
  { key: 'wechat', label: 'WeChat' },
  { key: 'mail', label: 'E-mail' },
  { key: 'comment', label: '備註' },
];

const styles = {
  commandBlock: {
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  expand: {
    flex: 1,
  },
  chartBox: {
    width: '100%',
    height: 400,
  },
};

const chartRanges = ['0', '1', '2', 7, 14, 30, 60];

const Sleeper = ({
  serial, controlEnabled, side, data, onControlSuccess, scoreThreshold,
}) => {
  const [controlVisible, setControlVisible] = React.useState(false);
  const [chartRange, setChartRange] = React.useState(chartRanges[0]);

  const onSuccess = React.useCallback(() => {
    onControlSuccess();
    setControlVisible(false);
  }, [onControlSuccess, setControlVisible]);

  const major = React.useMemo(() => {
    if (controlEnabled) {
      return majorFields;
    }
    return majorFields.filter((field) => !field.needControl);
  }, [controlEnabled]);

  return (
    <>
      <InfoDescription
        title={data.name}
        data={data}
        major={major}
        minor={minorFields}
        column={3}
      />
      <div hidden={!controlEnabled} style={styles.commandBlock}>
        <Select value={chartRange} onChange={setChartRange}>
          {chartRanges.map((range) => {
            let label;
            if (typeof range === 'string') {
              label = moment().subtract(range, 'month').format('YYYY/MM');
            } else {
              label = `最近 ${range} 天`;
            }
            return <Select.Option key={range} value={range}>{label}</Select.Option>;
          })}
        </Select>
        <div style={styles.expand} />
        <Popover
          trigger="click"
          content={<ModuleConfig serial={serial} side={side} onSuccess={onSuccess} />}
          placement="topRight"
          visible={controlVisible}
          onVisibleChange={setControlVisible}
        >
          <Button>手動調整</Button>
        </Popover>
      </div>
      <div style={styles.chartBox}>
        <Chart range={chartRange} account={data.account} threshold={scoreThreshold} />
      </div>
    </>
  );
};

Sleeper.propTypes = {
  serial: PropTypes.string.isRequired,
  side: PropTypes.string.isRequired,
  controlEnabled: PropTypes.bool.isRequired,
  scoreThreshold: PropTypes.number.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    account: PropTypes.string.isRequired,
  }).isRequired,
  onControlSuccess: PropTypes.func.isRequired,
};

export default Sleeper;
