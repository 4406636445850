import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table, Button } from 'antd';
import { LineChartOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import NestedList from './NestedList';

const typeMap = {
  intelligent: '智慧床',
  observant: '數據床',
};

const statusMap = {
  manufacture: '工廠製造中',
  deliver: '出貨中',
  activated: '已安裝',
};

const listColumns = (history) => [{
  title: '床組編號',
  dataIndex: 'serial',
}, {
  title: '類別',
  dataIndex: 'type',
  width: 150,
  render: (value) => typeMap[value],
}, {
  title: '使用者',
  key: 'users',
  width: 150,
  render: ({ left, right }) => `${left.name}${right ? ', ' : ''}${right ? right.name : ''}`,
}, {
  title: '狀態',
  dataIndex: 'status',
  width: 150,
  render: (value) => statusMap[value],
}, {
  title: '啟動時間',
  dataIndex: 'activated',
  width: 150,
  render: (value) => value && moment(value).format('YYYY/MM/DD'),
}, {
  title: '調整訂閱到期日',
  dataIndex: 'controlExpire',
  width: 150,
  align: 'center',
  render: (value) => value && moment(value).format('YYYY/MM/DD'),
}, {
  title: '',
  width: 150,
  render: (value) => (
    <Button icon={<LineChartOutlined />} onClick={() => history.push('/customers/detail', value.serial)} />
  ),
}];

const List = ({ items }) => {
  const history = useHistory();
  const columns = React.useMemo(() => listColumns(history), [history]);

  return (
    <Table
      dataSource={items}
      columns={columns}
      rowKey="serial"
      expandable={{ expandedRowRender: NestedList }}
    />
  );
};

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    left: PropTypes.object.isRequired,
    right: PropTypes.object,
    serial: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    status: PropTypes.string,
    activate: PropTypes.number,
  })).isRequired,
};

export default List;
