import React from 'react';
import PropTypes from 'prop-types';
import { Input, Select, Space } from 'antd';

const typeOptions = [
  { label: '顯示全部', value: 'all' },
  { label: '顯示智慧床', value: 'intelligent' },
  { label: '顯示數據床', value: 'observant' },
];

const expireOptions = [
  { label: '顯示全部', value: 'all' },
  { label: '資料訂閱即將到期', value: 'dataExpire' },
  { label: '調整訂閱即將到期', value: 'controlExpire' },
];

const styles = {
  select: {
    width: 200,
  },
};

const Filter = ({ filter, onFilterChanged }) => {
  const updateSearch = React.useCallback((e) => {
    onFilterChanged({ ...filter, search: e.target.value });
  }, [filter, onFilterChanged]);

  const updateType = React.useCallback((newType) => {
    const updated = { type: newType };
    if (newType === 'observant' && filter.expire === 'control') {
      updated.expire = 'all';
    }
    onFilterChanged({ ...filter, ...updated });
  }, [filter, onFilterChanged]);

  const updateExpire = React.useCallback((newExpire) => {
    onFilterChanged({ ...filter, expire: newExpire });
  }, [filter, onFilterChanged]);

  return (
    <Space>
      <Select value={filter.type} onChange={updateType} style={styles.select}>
        {typeOptions.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
      <Select value={filter.expire} onChange={updateExpire} style={styles.select}>
        {expireOptions.map((option) => (
          <Select.Option
            key={option.value}
            value={option.value}
            disabled={option.value === 'control' && filter.type === 'observant'}
          >
            {option.label}
          </Select.Option>
        ))}
      </Select>
      <Input value={filter.search} placeholder="依編號或使用者名稱搜尋" onChange={updateSearch} />
    </Space>
  );
};

Filter.propTypes = {
  filter: PropTypes.shape({
    search: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    expire: PropTypes.string.isRequired,
  }).isRequired,
  onFilterChanged: PropTypes.func.isRequired,
};

export default Filter;
