import React from 'react';
import {
  Input, Button, Form, Spin,
} from 'antd';
import APIContext from '../../contexts/APIContext';

const styles = {
  container: {
    padding: 60,
  },
  textArea: {
    resize: 'none',
  },
};

const labelCol = { span: 8 };
const wrapperCol = { span: 12 };
const buttonWrapperCol = { offset: 8, span: 12 };
const templateRules = [{
  validator: (_, value) => (
    value.includes('%%%')
      ? Promise.resolve()
      : Promise.reject(new Error('模板需包含 "%%%" 作為到期日顯示變數，例如：訂閱將於%%%到期'))
  ),
}];

const System = () => {
  const [settings, setSettings] = React.useState(null);
  const { callAPI, APIs } = React.useContext(APIContext);

  const submit = React.useCallback((values) => {
    callAPI(APIs.updateSystem, values);
  }, [callAPI, APIs]);

  React.useEffect(() => {
    callAPI(APIs.getSystem)
      .then((res) => {
        setSettings(res);
      });
  }, [callAPI, APIs]);

  if (!settings) {
    return (
      <div style={styles.container}>
        <Spin />
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <div style={styles.panel}>
        <Form
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          onFinish={submit}
          initialValues={settings}
        >
          <Form.Item
            name="dataExpireTemplate"
            label="資料訂閱到期通知模板"
            rules={templateRules}
          >
            <Input.TextArea rows={6} style={styles.textArea} />
          </Form.Item>
          <Form.Item
            name="controlExpireTemplate"
            label="控制訂閱到期通知模板"
            rules={templateRules}
          >
            <Input.TextArea rows={6} style={styles.textArea} />
          </Form.Item>
          <Form.Item wrapperCol={buttonWrapperCol}>
            <Button type="primary" htmlType="submit">儲存</Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default System;
