import React from 'react';
import moment from 'moment';
import {
  Table, Form, Input, DatePicker, Button,
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import APIContext from '../../contexts/APIContext';

const styles = {
  container: {
    padding: 60,
  },
  formBlock: {
    marginBottom: 20,
  },
};

const roleMap = {
  admin: '系統管理員',
  staff: '客服人員',
  driver: '司機',
  manufacturer: '工廠製造員',
  client: '資料串接',
};

const commandMap = {
  adjust: '控制床組',
  ventilation: '通風',
  refill: '補氣',
};

const sideMap = {
  left: '左床',
  right: '右床',
};

const transformFilter = (filter) => {
  const { date, ...rest } = filter;
  const condition = {};
  Object.keys(rest).forEach((key) => {
    if (filter[key]) {
      condition[key] = filter[key];
    }
  });

  if (!date) {
    return condition;
  }

  const since = date[0].startOf('day').valueOf();
  const until = date[1].endOf('day').valueOf();
  return { ...condition, since, until };
};

const parseLog = ({ event, payload }) => {
  switch (event) {
    case 'create-user':
      return `建立${roleMap[payload.role]}帳號: ${payload.name}`;
    case 'update-user':
      return `編輯${roleMap[payload.role]}帳號: ${payload.name}`;
    case 'delete-user':
      return `刪除${roleMap[payload.role]}帳號: ${payload.name}`;
    case 'login':
      return '登入';
    case 'logout':
      return '登出';
    case 'create-device':
      return '建立床組';
    case 'update-device':
      return '編輯床組';
    case 'delete-device':
      return '刪除床組';
    case 'activate-device':
      return '啟用床組';
    case 'ping':
      return '確認 Gateway 連線成功';
    case 'ventilation':
      return '手動通風';
    case 'refill':
      return '手動補氣';
    case 'custom':
      return '手動調整模組';
    case 'reschedule':
      return '重新排程';
    case 'commanded':
      return `成功發送${commandMap[payload.type] || payload.type}命令`;
    case 'score':
      return `取得${sideMap[payload.side]}睡眠分數`;
    default:
      return event;
  }
};

const Logs = () => {
  const { state } = useLocation();
  const [logs, setLogs] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState({ current: 1, pageSize: 10 });
  const [filter, setFilter] = React.useState({ device: state });
  const [form] = Form.useForm();
  const { callAPI, APIs } = React.useContext(APIContext);
  const history = useHistory();

  const fetchLogs = React.useCallback((condition, offset) => {
    setLoading(true);
    const body = { ...transformFilter(condition), offset };

    callAPI(APIs.log, body)
      .then((newLogs) => {
        setLogs((original) => {
          if (offset >= original.length) {
            const blank = Array(offset - original.length);
            return original.concat(blank).concat(newLogs);
          }

          const before = original.slice(0, offset);
          const after = original.slice(offset + newLogs.length);
          return before.concat(newLogs).concat(after);
        });
        setLoading(false);
      });
  }, [callAPI, APIs]);

  const fetchLogCount = React.useCallback((condition) => {
    const body = transformFilter(condition);
    callAPI(APIs.logCount, body)
      .then((total) => {
        setPage((original) => ({ ...original, total }));
      });
  }, [callAPI, APIs]);

  const onPageChange = React.useCallback((pagination) => {
    const { current, pageSize } = pagination;
    const offset = (current - 1) * pageSize;
    if (offset >= logs.length || !logs[offset]) {
      fetchLogs(filter, offset);
    }
    setPage(pagination);
  }, [logs, filter, fetchLogs]);

  const onSearch = React.useCallback((condition) => {
    setFilter(condition);
    setLogs([]);
    setPage((origin) => ({ ...origin, current: 1 }));
    form.resetFields();
  }, [form]);

  React.useEffect(() => {
    Promise.all([
      fetchLogCount(filter),
      fetchLogs(filter, 0),
    ]);
  }, [filter, fetchLogCount, fetchLogs]);

  const columns = React.useMemo(() => [{
    title: '日期',
    dataIndex: 'time',
    sorter: true,
    render: (time) => moment(time).format('YYYY/MM/DD HH:mm:ss'),
    width: '25%',
  }, {
    title: '操作人員',
    dataIndex: 'operator',
    width: '25%',
    render: (operator) => (
      <Button type="link" onClick={() => onSearch({ operator })}>
        {operator}
      </Button>
    ),
  }, {
    title: '動作',
    width: '25%',
    render: parseLog,
  }, {
    title: '目標床組',
    dataIndex: 'device',
    width: '25%',
    render: (value) => (
      <Button type="link" onClick={() => history.push('/customers/detail', value)}>
        {value}
      </Button>
    ),
  }], [onSearch, history]);

  return (
    <div style={styles.container}>
      <div style={styles.formBlock}>
        <Form onFinish={onSearch} form={form} layout="inline">
          <Form.Item name="operator">
            <Input placeholder="操作人員" />
          </Form.Item>
          <Form.Item name="device">
            <Input placeholder="床組編號" />
          </Form.Item>
          <Form.Item name="date">
            <DatePicker.RangePicker />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">搜尋</Button>
          </Form.Item>
        </Form>
      </div>
      <Table
        columns={columns}
        rowKey="_id"
        pagination={page}
        dataSource={logs}
        loading={loading}
        onChange={onPageChange}
      />
    </div>
  );
};

export default Logs;
