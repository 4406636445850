import React from 'react';
import {
  Form, Divider, Select, Input, InputNumber, Col, Row, Cascader, Button, Space,
  DatePicker, Popconfirm,
} from 'antd';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { getFields, defaultValues } from './fields';
import APIContext from '../../../../contexts/APIContext';

const labelCol = { span: 8 };
const wrapperCol = { span: 12 };
const buttonWrapperCol = { offset: 8, span: 12 };
const styles = {
  container: { padding: 60 },
  submitButton: { float: 'right' },
};

const sleeperOptions = [
  { value: false, label: '單人' },
  { value: true, label: '雙人' },
];

const typeOptions = [
  { value: 'intelligent', label: '智慧床' },
  { value: 'observant', label: '數據床' },
];

const renderFormField = (field) => {
  let control = <Input />;
  if (field.options) {
    if (field.options.some((option) => option.children !== undefined)) {
      control = <Cascader options={field.options} />;
    } else {
      control = <Select options={field.options} />;
    }
  } else if (field.isNumber) {
    control = <InputNumber />;
  } else if (field.isDate) {
    control = <DatePicker />;
  }

  return (
    <Form.Item
      key={field.name}
      name={field.name}
      label={field.label}
      rules={field.optional ? [] : [{ required: true, message: `請輸入${field.label}` }]}
    >
      {control}
    </Form.Item>
  );
};

const renderSleeperFormFields = (field, side) => {
  const { name, ...rest } = field;
  return renderFormField({ ...rest, name: [side, name] });
};

const replaceTimestamp = (object, dateFields) => {
  const result = {};
  Object.keys(object).forEach((key) => {
    const found = dateFields.find(({ name }) => name === key);
    result[key] = found ? moment(object[key]) : object[key];
  });

  return result;
};

const replaceMoment = (object) => {
  const result = {};
  Object.keys(object).forEach((key) => {
    if (moment.isMoment(object[key])) {
      result[key] = object[key].endOf('day').valueOf();
    } else {
      result[key] = object[key];
    }
  });
  return result;
};

const Editor = () => {
  const history = useHistory();
  const { state: device } = useLocation();

  const [double, setDouble] = React.useState(device !== undefined && device.right !== undefined);
  const [type, setType] = React.useState(device ? device.type : 'intelligent');
  const { callAPI, APIs } = React.useContext(APIContext);

  const fields = React.useMemo(() => getFields(type, device), [type, device]);
  const initialValues = React.useMemo(() => {
    const { info, config, user } = fields;
    const isDates = info.concat(config).concat(user).filter(({ isDate }) => isDate);

    const values = { ...defaultValues, ...device };
    const { left, right, ...rest } = values;
    const result = { ...replaceTimestamp(rest, isDates) };
    if (left) {
      result.left = replaceTimestamp(left, isDates);
    }
    if (right) {
      result.right = replaceTimestamp(right, isDates);
    }
    return result;
  }, [device, fields]);

  const submit = React.useCallback((values) => {
    const { left, right, ...rest } = values;
    const body = {
      ...replaceMoment(rest),
      type,
      left: replaceMoment(left),
    };

    if (right) {
      body.right = replaceMoment(right);
    }

    const api = device ? APIs.updateDevice : APIs.createDevice;
    callAPI(api, body)
      .then(() => history.push('/customers'));
  }, [callAPI, APIs, history, type, device]);

  const cancel = React.useCallback(() => {
    history.push('/customers');
  }, [history]);

  const deleteDevice = React.useCallback(() => {
    callAPI(APIs.deleteDevice, { serial: device.serial })
      .then(() => history.push('/customers'));
  }, [device, history, callAPI, APIs]);

  return (
    <div style={styles.container}>
      <Select value={type} onChange={setType} options={typeOptions} />
      <Form
        initialValues={initialValues}
        labelCol={labelCol}
        wrapperCol={wrapperCol}
        onFinish={submit}
      >
        <Divider>床組設定</Divider>
        <Row>
          <Col span={12}>{fields.info.map((field) => renderFormField(field))}</Col>
          <Col span={12}>{fields.config.map((field) => renderFormField(field))}</Col>
        </Row>
        <Select value={double} onChange={setDouble} options={sleeperOptions} />

        <Row gutter={24}>
          {double && (
            <Col span={12}>
              <Divider>右床使用者設定</Divider>
              {fields.user.map((field) => renderSleeperFormFields(field, 'right'))}
            </Col>
          )}
          <Col span={double ? 12 : 24}>
            <Divider>{`${double ? '左床' : ''}使用者設定`}</Divider>
            {fields.user.map((field) => renderSleeperFormFields(field, 'left'))}
            <Form.Item wrapperCol={buttonWrapperCol}>
              <div style={styles.submitButton}>
                <Space>
                  <Popconfirm
                    title="刪除的裝置無法回復，是否確定要刪除？"
                    okText="是"
                    cancelText="否"
                    onConfirm={deleteDevice}
                  >
                    <Button danger>刪除</Button>
                  </Popconfirm>
                  <Button onClick={cancel}>取消</Button>
                  <Button type="primary" htmlType="submit">送出</Button>
                </Space>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Editor;
