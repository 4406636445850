const determineStatus = (user, deviceStatus) => {
  let status;
  if (deviceStatus !== 'activated') {
    status = undefined;
  } else if (user.persist.custom) {
    status = 'custom';
  } else if (!user.persist.schedule || user.persist.schedule <= 0) {
    status = 'monitor';
  } else {
    status = 'schedule';
  }
  return status;
};

export default determineStatus;
