import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Switch, Route, useHistory, useLocation,
} from 'react-router-dom';
import { Button, Menu, notification } from 'antd';
import APIContext from '../../contexts/APIContext';
import { request, APIs } from '../../api';
import AccountList from './Account/List';
import AccountEditor from './Account/Editor';
import CustomerDashboard from './Customer/Dashboard';
import CustomerEditor from './Customer/Editor';
import CustomerDetail from './Customer/Detail';
import Activate from './Activate';
import Factory from './Factory';
import Logs from './Logs';
import Errors from './Errors';
import Alerts from './Alerts';
import BigData from './BigData';
import System from './System';
import Algorithm from './Algorithm';
import { RestrictedRoute, RedirectDefault } from './RestrictedRoute';

const styles = {
  logoutButton: {
    float: 'right',
    margin: 8,
  },
};

const translateNotification = ({
  event, device, last, time,
}) => {
  const day = moment(time).diff(moment(last), 'day');
  switch (event) {
    case 'score':
      return `床組 ${device} 已 ${day} 天未取得睡眠分數`;
    case 'ping':
      return `床組 ${device} 已 ${day} 天未回應確認連線訊息`;
    default:
      return '';
  }
};

const Main = ({ onLogout, user }) => {
  const history = useHistory();
  const location = useLocation();
  const [notified, setNotified] = React.useState(0);

  const callAPI = React.useCallback((api, params) => (
    request(api, params)
      .catch((err) => {
        if (err.status === 401) {
          onLogout();
        }
        return Promise.reject(err);
      })
  ), [onLogout]);

  const value = React.useMemo(() => ({ APIs, callAPI }), [callAPI]);

  const logout = React.useCallback(() => {
    request(APIs.logout)
      .then(onLogout);
  }, [onLogout]);

  const handleMenuClick = React.useCallback((e) => {
    history.push(`/${e.key}`);
  }, [history]);

  const dismiss = React.useCallback((id) => {
    callAPI(APIs.dismiss, { _id: id })
      .then(() => notification.close(id));
  }, [callAPI]);

  const selectedPath = React.useMemo(() => {
    const { pathname } = location;
    const key = pathname.split('/')[1];
    return [key];
  }, [location]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setNotified(Date.now());
    }, 5 * 1000);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    callAPI(APIs.notification, { time: notified })
      .then((res) => {
        res.forEach((note) => {
          const description = translateNotification(note);
          if (!description.length) {
            return;
          }
          const { _id: id } = note;
          const btn = <Button size="small" onClick={() => dismiss(id)}>不再通知</Button>;
          const args = {
            message: '錯誤警報',
            description,
            duration: 0,
            btn,
            key: id,
          };
          notification.open(args);
        });
      });
  }, [notified, callAPI, dismiss]);

  return (
    <APIContext.Provider value={value}>
      <Button style={styles.logoutButton} onClick={logout}>登出</Button>
      {(user.role === 'admin' || user.role === 'staff') && (
        <Menu onClick={handleMenuClick} selectedKeys={selectedPath} mode="horizontal">
          <Menu.Item key="customers">客戶資料管理</Menu.Item>
          {user.role === 'admin' && <Menu.Item key="accounts">帳號管理</Menu.Item>}
          {user.role === 'admin' && <Menu.Item key="logs">工作記錄總覽</Menu.Item>}
          <Menu.Item key="errors">即時錯誤總覽</Menu.Item>
          <Menu.Item key="alerts">警報總覽</Menu.Item>
          <Menu.Item key="bigdata">大數據總覽</Menu.Item>
          <Menu.Item key="system">系統設定</Menu.Item>
          <Menu.Item key="algorithm">演算法調整</Menu.Item>
        </Menu>
      )}

      <Switch>
        <RestrictedRoute role={user.role} path="/errors"><Errors /></RestrictedRoute>
        <RestrictedRoute role={user.role} path="/logs"><Logs /></RestrictedRoute>
        <RestrictedRoute role={user.role} path="/alerts"><Alerts /></RestrictedRoute>
        <RestrictedRoute role={user.role} path="/accounts/edit"><AccountEditor /></RestrictedRoute>
        <RestrictedRoute role={user.role} path="/accounts"><AccountList /></RestrictedRoute>
        <RestrictedRoute role={user.role} path="/customers/detail"><CustomerDetail /></RestrictedRoute>
        <RestrictedRoute role={user.role} path="/customers/edit"><CustomerEditor /></RestrictedRoute>
        <RestrictedRoute role={user.role} path="/customers"><CustomerDashboard /></RestrictedRoute>
        <RestrictedRoute role={user.role} path="/activate"><Activate /></RestrictedRoute>
        <RestrictedRoute role={user.role} path="/factory"><Factory /></RestrictedRoute>
        <RestrictedRoute role={user.role} path="/bigdata"><BigData /></RestrictedRoute>
        <RestrictedRoute role={user.role} path="/system"><System /></RestrictedRoute>
        <RestrictedRoute role={user.role} path="/algorithm"><Algorithm /></RestrictedRoute>
        <Route path="/"><RedirectDefault role={user.role} /></Route>
      </Switch>
    </APIContext.Provider>
  );
};

Main.propTypes = {
  onLogout: PropTypes.func.isRequired,
  user: PropTypes.shape({
    role: PropTypes.string.isRequired,
  }).isRequired,
};

export default Main;
