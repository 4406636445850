import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Table } from 'antd';

const statusMap = {
  monitor: '數據監測中',
  schedule: '模組排程中',
  custom: '手動固定模組',
};

const styles = {
  container: {
    paddingLeft: 80,
    paddingRight: 80,
  },
};

const columns = [{
  title: '姓名',
  dataIndex: 'name',
  width: '15%',
  align: 'left',
}, {
  title: '模組',
  dataIndex: 'module',
  width: '15%',
  align: 'center',
  render: (value) => (value < 0 ? '客製化設定' : value),
}, {
  title: '排程天數',
  dataIndex: 'schedule',
  width: '15%',
  align: 'center',
  render: (value) => (value < 0 ? '排程已結束' : value),
}, {
  title: '狀態',
  dataIndex: 'status',
  width: '15%',
  align: 'center',
  render: (value) => statusMap[value],
}, {
  title: '資料訂閱到期日',
  dataIndex: 'dataExpire',
  width: '25%',
  align: 'center',
  render: (value) => value && moment(value).format('YYYY/MM/DD'),
}];

const NestedList = ({ left, right }) => {
  const users = right ? [left, right] : [left];

  return (
    <div style={styles.container}>
      <Table columns={columns} size="small" dataSource={users} rowKey="account" pagination={false} />
    </div>
  );
};

NestedList.propTypes = {
  left: PropTypes.shape({
    name: PropTypes.string.isRequired,
    module: PropTypes.string.isRequired,
    schedule: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    dataExpire: PropTypes.number.isRequired,
    controlExpire: PropTypes.number.isRequired,
  }).isRequired,
  right: PropTypes.shape({
    name: PropTypes.string.isRequired,
    module: PropTypes.string.isRequired,
    schedule: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    dataExpire: PropTypes.number.isRequired,
    controlExpire: PropTypes.number.isRequired,
  }),
};

NestedList.defaultProps = {
  right: null,
};

export default NestedList;
