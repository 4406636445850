import React from 'react';
import moment from 'moment';
import {
  Table, Form, Input, Button,
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import APIContext from '../../contexts/APIContext';

const styles = {
  container: {
    padding: 60,
  },
  formBlock: {
    marginBottom: 20,
  },
};

const parseError = ({ event, last, time }) => {
  const day = moment(time).diff(moment(last), 'day');
  if (event === 'ping') {
    return `已 ${day} 天未回應確認床組連線訊息`;
  }

  if (event === 'score') {
    return `已 ${day} 天未取得床組睡眠分數`;
  }

  return '';
};

const transformFilter = (filter) => {
  const { device } = filter;
  const condition = device ? { device } : {};
  return condition;
};

const Errors = () => {
  const { state } = useLocation();
  const [errors, setErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState({ current: 1, pageSize: 10 });
  const [filter, setFilter] = React.useState({ device: state });
  const [form] = Form.useForm();
  const { callAPI, APIs } = React.useContext(APIContext);
  const history = useHistory();

  const fetchErrors = React.useCallback((condition, offset) => {
    setLoading(true);
    const body = { ...transformFilter(condition), offset };

    callAPI(APIs.error, body)
      .then((newErrors) => {
        setErrors((original) => {
          if (offset >= original.length) {
            const blank = Array(offset - original.length);
            return original.concat(blank).concat(newErrors);
          }

          const before = original.slice(0, offset);
          const after = original.slice(offset + newErrors.length);
          return before.concat(newErrors).concat(after);
        });
        setLoading(false);
      });
  }, [callAPI, APIs]);

  const fetchErrorCount = React.useCallback((condition) => {
    const body = transformFilter(condition);
    callAPI(APIs.errorCount, body)
      .then((total) => {
        setPage((original) => ({ ...original, total }));
      });
  }, [callAPI, APIs]);

  const onPageChange = React.useCallback((pagination) => {
    const { current, pageSize } = pagination;
    const offset = (current - 1) * pageSize;
    if (offset >= errors.length || !errors[offset]) {
      fetchErrors(filter, offset);
    }
    setPage(pagination);
  }, [errors, filter, fetchErrors]);

  const onSearch = React.useCallback((condition) => {
    setFilter(condition);
    setErrors([]);
    setPage((origin) => ({ ...origin, current: 1 }));
    form.resetFields();
  }, [form]);

  React.useEffect(() => {
    Promise.all([
      fetchErrorCount(filter),
      fetchErrors(filter, 0),
    ]);
  }, [filter, fetchErrorCount, fetchErrors]);

  const columns = React.useMemo(() => [{
    title: '日期',
    dataIndex: 'time',
    sorter: true,
    render: (time) => moment(time).format('YYYY/MM/DD HH:mm:ss'),
    width: '33%',
  }, {
    title: '錯誤事項',
    render: parseError,
    width: '33%',
  }, {
    title: '目標床組',
    dataIndex: 'device',
    width: '33%',
    render: (value) => (
      <Button type="link" onClick={() => history.push('/customers/detail', value)}>
        {value}
      </Button>
    ),
  }], [history]);

  return (
    <div style={styles.container}>
      <div style={styles.formBlock}>
        <Form onFinish={onSearch} form={form} layout="inline">
          <Form.Item name="device">
            <Input placeholder="床組編號" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">搜尋</Button>
          </Form.Item>
        </Form>
      </div>
      <Table
        columns={columns}
        rowKey="_id"
        pagination={page}
        dataSource={errors}
        loading={loading}
        onChange={onPageChange}
      />
    </div>
  );
};

export default Errors;
