import React from 'react';
import {
  Spin, Form, Button, Input,
} from 'antd';
import APIContext from '../../contexts/APIContext';

const styles = {
  container: {
    padding: 60,
  },
  element: {
    display: 'inline-block',
    width: 'calc(50% - 8px)',
    marginLeft: 8,
  },
};

const labelCol = { span: 8 };
const wrapperCol = { span: 12 };
const buttonWrapperCol = { offset: 8, span: 12 };

const configElements = [{
  section: '躁動不安',
  elements: [{
    name: 'restless',
    desc: '睡眠時間裡moving超過',
    unit: '次',
  }],
}, {
  section: '難以入睡',
  elements: [{
    name: 'sleepless',
    desc: '入睡時間超過',
    unit: '分',
  }],
}, {
  section: '呼吸過速',
  elements: [{
    name: 'rapidBreath',
    desc: '每小時平均呼吸超過',
    unit: '次',
  }],
}, {
  section: '呼吸過緩',
  elements: [{
    name: 'slowBreath',
    desc: '每小時平均呼吸低於',
    unit: '次',
  }],
}, {
  section: '太晚睡',
  elements: [{
    name: 'sleepLate',
    desc: '超過',
    unit: '點上床，每晚一小時',
  }],
}, {
  section: '睡覺易醒',
  elements: [{
    name: 'easyAwaken',
    desc: '每出現 awake',
    unit: '次',
  }, {
    name: 'easyAbsence',
    desc: '每出現 absence',
    unit: '次',
  }],
}, {
  section: '呼吸異常',
  elements: [{
    name: 'abnormalRapidBreath',
    desc: '每小時平均呼吸高於(包含)',
    unit: '次',
  }, {
    name: 'abnormalSlowBreath',
    desc: '每小時平均呼吸低於(包含)',
    unit: '次',
  }],
}, {
  section: '良性睡眠分佈',
  elements: [{
    name: 'deepShallowRatio',
    desc: '深睡%<淺睡%，每小於',
    unit: '%',
  }, {
    name: 'shallowAwakeRatio',
    desc: '淺睡%<清醒%(含離開)',
    unit: '%',
  }],
}];

const rules = [{ required: true, message: '請輸入設定值' }];

const convertToForm = ({ splitter, ...rest }) => {
  const converted = {
    splitter: { awake: splitter.awake / 60, absence: splitter.absence / 60 },
  };
  Object.keys(rest).forEach((key) => {
    converted[key] = { diff: -rest[key].diff };

    if (key === 'sleepless') {
      converted[key].threshold = rest[key].threshold / 60;
    } else {
      converted[key].threshold = rest[key].threshold;
    }
  });
  return converted;
};

const convertToBody = ({ splitter, ...rest }) => {
  const converted = {
    splitter: { awake: splitter.awake * 60, absence: splitter.absence * 60 },
  };

  Object.keys(rest).forEach((key) => {
    converted[key] = { diff: -rest[key].diff };

    if (key === 'sleepless') {
      converted[key].threshold = rest[key].threshold * 60;
    } else {
      converted[key].threshold = rest[key].threshold;
    }
  });
  return converted;
};

const ScoreConfig = () => {
  const [settings, setSettings] = React.useState(null);
  const { callAPI, APIs } = React.useContext(APIContext);

  const submit = React.useCallback((values) => {
    callAPI(APIs.updateMonitorConfig, convertToBody(values));
  }, [callAPI, APIs]);

  React.useEffect(() => {
    callAPI(APIs.getMonitorConfig)
      .then((res) => {
        setSettings(convertToForm(res));
      });
  }, [callAPI, APIs]);

  if (!settings) {
    return (
      <div style={styles.container}>
        <Spin />
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <div style={styles.panel}>
        <Form
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          onFinish={submit}
          initialValues={settings}
        >
          <Form.Item key="splitter" label="睡眠中斷條件">
            <Input.Group compact>
              <Form.Item
                name={['splitter', 'awake']}
                rules={rules}
                style={styles.element}
              >
                <Input type="number" addonBefore="持續 awake" addonAfter="分" />
              </Form.Item>
              <Form.Item
                name={['splitter', 'absence']}
                rules={rules}
                style={styles.element}
              >
                <Input type="number" addonBefore="持續 absence" addonAfter="分" />
              </Form.Item>
            </Input.Group>

          </Form.Item>
          {
            configElements.map((config) => (
              <Form.Item key={config.section} label={config.section}>
                {
                  config.elements.map((element) => (
                    <Form.Item key={element.name}>
                      <Input.Group compact>
                        <Form.Item
                          name={[element.name, 'threshold']}
                          rules={rules}
                          style={styles.element}
                          normalize={element.convert}
                        >
                          <Input type="number" addonBefore={element.desc} addonAfter={element.unit} />
                        </Form.Item>
                        <Form.Item
                          name={[element.name, 'diff']}
                          rules={rules}
                          style={styles.element}
                        >
                          <Input type="number" addonBefore="扣" addonAfter="分" />
                        </Form.Item>
                      </Input.Group>
                    </Form.Item>
                  ))
                }
              </Form.Item>
            ))
          }
          <Form.Item wrapperCol={buttonWrapperCol}>
            <Button type="primary" htmlType="submit">儲存</Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ScoreConfig;

/*

<Form
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          onFinish={submit}
          initialValues={settings}
        >
          <Form.Item
            name="dataExpireTemplate"
            label="資料訂閱到期通知模板"
            rules={templateRules}
          >
            <Input.TextArea rows={6} style={styles.textArea} />
          </Form.Item>
          <Form.Item
            name="controlExpireTemplate"
            label="控制訂閱到期通知模板"
            rules={templateRules}
          >
            <Input.TextArea rows={6} style={styles.textArea} />
          </Form.Item>
          <Form.Item wrapperCol={buttonWrapperCol}>
            <Button type="primary" htmlType="submit">儲存</Button>
          </Form.Item>
        </Form>
*/
