import React from 'react';
import { Button, Alert } from 'antd';
import APIContext from '../../contexts/APIContext';
import InfoPanel from './InfoPanel';

const styles = {
  container: {
    padding: 60,
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
  },
};

const fields = [
  { key: 'serial', label: '床組編號' },
  { key: 'gateway', label: 'Gateway' },
  { key: 'address', label: '地址' },
  { key: 'names', label: '姓名', merge: 'name' },
  { key: 'phones', label: '電話', merge: 'phone' },
];

const condition = { status: 'deliver' };

const Activate = () => {
  const [activated, setActivated] = React.useState(false);
  const [info, setInfo] = React.useState(null);
  const { callAPI, APIs } = React.useContext(APIContext);

  const activate = React.useCallback(() => {
    callAPI(APIs.activateDevice, { serial: info.serial }).then(() => {
      setInfo(null);
      setActivated(true);
    });
  }, [info, callAPI, APIs]);

  const updateInfo = React.useCallback((newInfo) => {
    setInfo(newInfo);
    setActivated(false);
  }, [setInfo, setActivated]);

  return (
    <div style={styles.container}>
      {activated && <Alert message="床組啟用成功" banner closable type="success" />}
      <div style={styles.content}>
        <InfoPanel
          info={info}
          fields={fields}
          onFetched={updateInfo}
          condition={condition}
        />
      </div>
      <div style={styles.content}>
        <Button type="primary" hidden={!info} onClick={activate}>啟用床組</Button>
      </div>
    </div>
  );
};

export default Activate;
