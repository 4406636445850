import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Line } from '@ant-design/charts';
import APIContext from '../../../../contexts/APIContext';

const annotations = (threshold) => [{
  type: 'regionFilter',
  start: ['min', threshold],
  end: ['max', '0'],
  color: '#F4664A',
}, {
  type: 'text',
  position: ['min', threshold],
  content: `標準分數 ${threshold}`,
  offsetY: -4,
  style: { textBaseline: 'bottom' },
}, {
  type: 'line',
  start: ['min', threshold],
  end: ['max', threshold],
  style: {
    stroke: '#F4664A',
    lineDash: [2, 2],
  },
}];

const cleanData = (since, rawData) => {
  const format = 'MM/DD';
  const start = moment(since);
  const dayCount = Math.ceil(moment().diff(start, 'day', true));
  const days = [...Array(dayCount).keys()].map((offset) => start.clone().add(offset, 'day').format(format));
  const cleaned = days.map((date) => {
    const found = rawData.find((raw) => moment(raw.end).format(format) === date) || {};
    const { score = 0, evaluation = {} } = found;
    const startTime = found.start ? moment(found.start).utc().format('kk:mm') : 'N/A';
    const endTime = found.end ? moment(found.end).utc().format('kk:mm') : 'N/A';
    return {
      date,
      score,
      startTime,
      endTime,
      ...evaluation,
    };
  });
  return cleaned;
};

const meta = {
  score: { alias: '睡眠分數' },
  startTime: { alias: '上床時間' },
  endTime: { alias: '離床時間' },
  restless: { alias: '躁動不安' },
  sleepless: { alias: '難以入睡' },
  rapidBreath: { alias: '呼吸過速' },
  slowBreath: { alias: '呼吸過緩' },
  sleepLate: { alias: '太晚睡' },
  easyAwaken: { alias: '睡覺易醒' },
  abnormalBreath: { alias: '呼吸異常' },
  sleepDistribution: { alias: '良性睡眠分佈' },
};

const tooltip = {
  fields: [
    'score',
    'startTime',
    'endTime',
    'restless',
    'sleepless',
    'rapidBreath',
    'slowBreath',
    'sleepLate',
    'easyAwaken',
    'abnormalBreath',
    'sleepDistribution',
  ],
};

const Chart = ({ account, range, threshold }) => {
  const [sleepData, setSleepData] = React.useState([]);
  const { callAPI, APIs } = React.useContext(APIContext);

  React.useEffect(() => {
    const since = moment().subtract(2, 'month').startOf('month').valueOf();
    callAPI(APIs.sleepData, { account, since })
      .then((rawData) => {
        setSleepData(cleanData(since, rawData));
      })
      .catch(() => setSleepData([]));
  }, [callAPI, APIs, account]);

  const filtered = React.useMemo(() => {
    if (typeof range === 'string') {
      const month = moment().subtract(range, 'month').format('MM');
      return sleepData.filter((data) => data.date.startsWith(month));
    }
    const after = moment().subtract(range, 'day').format('MM/DD');
    return sleepData.filter((data) => data.date > after);
  }, [sleepData, range]);

  const anno = React.useMemo(() => (threshold ? annotations(threshold) : []), [threshold]);

  if (!filtered.length) {
    return null;
  }

  return (
    <Line
      data={filtered}
      xField="date"
      yField="score"
      annotations={anno}
      meta={meta}
      tooltip={tooltip}
    />
  );
};

Chart.propTypes = {
  account: PropTypes.string.isRequired,
  threshold: PropTypes.number,
  range: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

Chart.defaultProps = {
  threshold: null,
};

export default Chart;
