import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, Space } from 'antd';
import APIContext from '../../../contexts/APIContext';

const centerStyle = {
  display: 'flex',
  justifyContent: 'center',
};

const Configurer = ({ onFinished, info }) => {
  const [tested, setTested] = React.useState([]);
  const { callAPI, APIs } = React.useContext(APIContext);

  const updateTested = React.useCallback((key) => setTested((original) => {
    if (original.includes(key)) {
      return original;
    }
    return original.concat([key]);
  }), [setTested]);

  const ventilate = React.useCallback(() => {
    const { serial, ventilationPeriod } = info;
    const body = { serial, period: ventilationPeriod };
    callAPI(APIs.ventilate, body)
      .then(() => updateTested('ventilate'));
  }, [info, callAPI, APIs, updateTested]);

  const refill = React.useCallback(() => {
    const { serial } = info;
    const body = { serial };
    callAPI(APIs.refill, body)
      .then(() => updateTested('refill'));
  }, [info, callAPI, APIs, updateTested]);

  const preset = React.useCallback(() => {
    const { serial, modules } = info;
    if (modules.length === 1) {
      modules.push(modules[0]);
    }
    const body = { serial, left: modules[0], right: modules[1] };
    callAPI(APIs.preset, body)
      .then(() => updateTested('preset'));
  }, [info, callAPI, APIs, updateTested]);

  return (
    <Space direction="vertical">
      <Space>
        <Button onClick={ventilate}>測試通風功能</Button>
        {
          tested.includes('ventilate')
            ? <Typography.Text type="success">命令已送出</Typography.Text>
            : <Typography.Text type="danger">動作未測試</Typography.Text>
        }
      </Space>

      <Space>
        <Button onClick={refill}>測試補氣功能</Button>
        {
          tested.includes('refill')
            ? <Typography.Text type="success">命令已送出</Typography.Text>
            : <Typography.Text type="danger">動作未測試</Typography.Text>
        }
      </Space>

      <Space>
        <Button onClick={preset}>調整預設模組</Button>
        {
          tested.includes('preset')
            ? <Typography.Text type="success">命令已送出</Typography.Text>
            : <Typography.Text type="danger">動作未執行</Typography.Text>
        }
      </Space>

      <div style={centerStyle}>
        <Button type="primary" onClick={onFinished} hidden={tested.length !== 3}>測試動作皆完成</Button>
      </div>
    </Space>
  );
};

Configurer.propTypes = {
  info: PropTypes.shape({
    serial: PropTypes.string.isRequired,
    ventilationPeriod: PropTypes.number.isRequired,
    modules: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  onFinished: PropTypes.func.isRequired,
};

export default Configurer;
