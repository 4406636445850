import React from 'react';
import {
  Form, Input, Button, Select, Space, Popconfirm,
} from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import APIContext from '../../../contexts/APIContext';

const styles = {
  container: {
    padding: 60,
  },
};

const layouts = {
  label: { offset: 4, span: 4 },
  input: { span: 8 },
  submit: { offset: 8 },
};

const roleOptions = [
  { value: 'admin', label: '系統管理員' },
  { value: 'staff', label: '客服人員' },
  { value: 'driver', label: '司機' },
  { value: 'manufacturer', label: '工廠製造員' },
  { value: 'client', label: '資料串接帳號' },
];

const User = () => {
  const history = useHistory();
  const location = useLocation();
  const user = location.state;
  const { callAPI, APIs } = React.useContext(APIContext);

  const submit = React.useCallback((values) => {
    const api = user ? APIs.updateUser : APIs.createUser;
    const { confirm, ...rest } = values;
    callAPI(api, rest)
      .then(() => history.push('/accounts'));
  }, [user, callAPI, APIs, history]);

  const cancel = React.useCallback(() => {
    history.push('/accounts');
  }, [history]);

  const deleteUser = React.useCallback(() => {
    callAPI(APIs.deleteUser, { account: user.account })
      .then(() => history.push('/accounts'));
  }, [history, user, callAPI, APIs]);

  return (
    <div style={styles.container}>
      <Form
        onFinish={submit}
        labelCol={layouts.label}
        wrapperCol={layouts.input}
        initialValues={user}
      >
        <Form.Item
          label="帳號"
          name="account"
          rules={[{ required: true, message: '請輸入帳號' }]}
          hasFeedback
        >
          <Input disabled={user} />
        </Form.Item>
        <Form.Item
          label="名稱"
          name="name"
          rules={[{ required: true, message: '請輸入名稱' }]}
          hasFeedback
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="密碼"
          name="password"
          rules={[{ required: true, message: '請輸入密碼' }]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="確認密碼"
          name="confirm"
          hasFeedback
          rules={[
            { required: true, message: '請再次輸入密碼' },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('兩次輸入的密碼不相同'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="身份"
          name="role"
          rules={[{ required: true, message: '請選擇身份' }]}
        >
          <Select>
            {roleOptions.map((option) => (
              <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={layouts.submit}>
          <Space>
            <Popconfirm
              title="刪除的帳號無法回復，是否確定要刪除？"
              okText="是"
              cancelText="否"
              onConfirm={deleteUser}
            >
              <Button danger>刪除</Button>
            </Popconfirm>
            <Button onClick={cancel}>取消</Button>
            <Button type="primary" htmlType="submit">送出</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default User;
