import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table } from 'antd';

const summaryColumns = [
  { title: '總人數', dataIndex: 'user', width: '25%' },
  { title: '今日收到分數筆數', dataIndex: 'score', width: '25%' },
  { title: '床組啟動天數總和', dataIndex: 'activate', width: '25%' },
  { title: '累計收到分數筆數', dataIndex: 'total', width: '25%' },
];

const getSummary = (devices, summary) => {
  if (!summary || !devices.length) {
    return {};
  }

  const serials = devices.map((device) => device.serial);
  const now = moment();

  return {
    score: summary.today.filter((record) => serials.indexOf(record.device) >= 0)
      .map((record) => record.count)
      .reduce((a, b) => a + b, 0),
    user: devices.map((device) => (device.right ? 2 : 1)).reduce((a, b) => a + b, 0),
    activate: devices.map(({ activated }) => (activated ? now.diff(moment(activated), 'day') : 0))
      .reduce((a, b) => a + b, 0),
  };
};

const Summary = ({ items, sumData }) => {
  const summary = React.useMemo(() => [{
    ...getSummary(items, sumData),
    total: sumData ? sumData.total : 0,
  }], [items, sumData]);

  return (
    <Table dataSource={summary} columns={summaryColumns} pagination={false} rowKey="total" />
  );
};

Summary.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    left: PropTypes.object.isRequired,
    right: PropTypes.object,
    serial: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    status: PropTypes.string,
    activate: PropTypes.number,
  })).isRequired,
  sumData: PropTypes.shape({
    total: PropTypes.number,
    today: PropTypes.array,
  }),
};

Summary.defaultProps = {
  sumData: {},
};

export default Summary;
